import React from 'react';
import Axios from "axios";
import { setItem, isLoggedIn } from "../services/auth"

class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          error: false
        };
        this.onPop = this.onSubmitMessage.bind(this);
    }

    onSubmitMessage = (e) => {
        e.preventDefault();
        console.log(e);
        const data = {
          username: e.target.email.value,
          password: e.target.password.value
        };
    
        var bodyFormData = new FormData();
        bodyFormData.append('username', e.target.email.value);
        bodyFormData.append('password', e.target.password.value);
        
        Axios.post('https://beta.lifestylefever.com/app_api.php?application=hybrid&type=user_login', bodyFormData)
          .then((result) => {
              console.log("result", result);
          }).catch((err) => {
              console.error(err);
          });
      };

    render() {
        return (
        <>
        <div className="w-full sm:px-6 lg:px-8 h-60 bg-slate-400">
        
        </div>

        <div className="container mt-2">
            <h1 className='text-2xl mb-16'>Contact</h1>

            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                  <h3 className="text-lg font-medium text-warm-gray-900">Connect with Club Mella</h3>
                  <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">If you have any questions about membership, events, press, or anything else, please use the form below to get in touch.  </p>
                  <form 
                    onSubmit={e => this.onSubmitMessage(e)}
                    method="POST" 
                    className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                    <div>
                      <label htmlFor="first-name" className="block text-sm font-medium text-warm-gray-900">
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label htmlFor="subject" className="block text-sm font-medium text-warm-gray-900">
                        Subject
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                          Message
                        </label>
                        <span id="message-max" className="text-sm text-warm-gray-500">
                          Max. 500 characters
                        </span>
                      </div>
                      <div className="mt-1">
                        <textarea
                          id="message"
                          name="message"
                          rows={4}
                          className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-warm-gray-300 rounded-md"
                          aria-describedby="message-max"
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                      <button
                        type="submit"
                        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

        </div>

        </>
        );
    }
}


export default AboutPage